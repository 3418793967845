<template>
  <div class="page">
    <bookIntro :options="book_info"></bookIntro>
    <div class="container">
      <infoMenu></infoMenu>
      <router-view />
    </div>
  </div>
</template>
<script>
import bookIntro from "./components/book_intro";
import infoMenu from "./components/book_info_menu";
import { books_detail } from "@/https/read_books.js";

export default {
  components: {
    bookIntro,
    infoMenu,
  },
  data() {
    return {
      book_info: {
        id: 1,
        book_img: require("@/assets/images/book_bc.jpg"),
        book_name: "作品名称",
        author: "作者名字",
        classArr: [
          {
            id: 1,
            classify: "女频",
          },
          {
            id: 2,
            classify: "穿越",
          },
          {
            id: 3,
            classify: "情感",
          },
        ],
        vip: "vip",
        status: "连载中",
        words: "23w",
        collect: "23w",
        click: "23w",
        good: "23w",
        new_chapter: "第八章 这你可就不懂了吧",
        time: "2020-01-01 11:22:12",
        book_intro: [
          "作品简介",
          "作品简介",
          "作品简介",
          "作品简介",
          "作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介作品简介",
        ],
        read_flag: false,
        rack_flag: false,
        cllect_flag: false,
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.resetSetItem("book_id", this.$route.query.id);
      // sessionStorage.setItem('book_id',this.$route.query.id);
    }
  },
  mounted() {
    // document.documentElement.scrollTop=0
    // sessionStorage.setItem('book_id',this.book_info.id);
    this.avoid_info();
    this.$store.state.messageVue.$on("getBookDetail", this.avoid_info);
  },
  methods: {
    //时间戳转换为日期
    timestampToTime(timestamp) {
      let date = new Date(timestamp),
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
        h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":",
        m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":",
        s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      return Y + M + D + h + m + s;
    },
    // 获取当前书籍信息
    avoid_info() {
      let params = {
        id: Number(sessionStorage.getItem("book_id")),
      };
      books_detail(params).then((res) => {
        if (res.code === 0) {
          res.data.update_time = this.timestampToTime(
            res.data.update_time * 1000
          );
          this.book_info = res.data;
          this.book_info.label = this.book_info.label.filter((item) => {
            return item.style != 2;
          });

          if (this.book_info.click > 99999) {
            this.book_info.click =
              (this.book_info.click / 10000).toFixed(2) + "w";
          }
          this.$store.commit("set_bookdetail", this.book_info);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
  watch: {
    "$route.query.id"() {
      this.resetSetItem("book_id", this.$route.query.id);
      // sessionStorage.setItem('book_id',this.$route.query.id);
      this.avoid_info();
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
}
</style>

<template>
  <div class="info_menu" flex align>
    <div
      v-for="(item, index) in menu_list"
      :key="index"
      class="info_menu_item"
      @click="changeRoute(item)"
      :class="menu_id === item.id ? 'choose_it' : ''"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu_list: [
        { name: "作品主页", id: 1, path: "/show_book/book_home" },
        { name: "作品目录", id: 2, path: "/show_book/book_catlog" },
      ],
      menu_id: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/show_book/book_home") {
        this.menu_id = 1;
        sessionStorage.setItem("book_menu_id", 1);
      }
      if (to.path === "/show_book/book_catlog") {
        this.menu_id = 2;
        sessionStorage.setItem("book_menu_id", 2);
      }
    },
  },
  beforeDestroy() {},
  created() {
    // let id = Number(sessionStorage.getItem("book_menu_id"));
    // if (id) {
    //   this.menu_id = id;
    // } else {
    //   this.menu_id = this.menu_list[0].id;
    // }
    this.avoid_path();
  },
  mounted() {},
  methods: {
    //点击菜单跳转
    changeRoute(row) {
      // sessionStorage.setItem("book_menu_id", row.id);
      this.menu_id = row.id;
      this.$router.push({
        path: `${row.path}`,
        query: {
          id: this.$route.query.id,
        },
      });
    },
    // 路径
    avoid_path() {
      if (this.$route.path === "/show_book/book_home") {
        this.menu_id = 1;
        // sessionStorage.setItem("book_menu_id",1)
      }
      if (this.$route.path === "/show_book/book_catlog") {
        this.menu_id = 2;
        // sessionStorage.setItem("book_menu_id",2)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.info_menu {
  width: 100%;
  justify-content: start;
  font-size: 18px;
  margin: 20px 0;
  > div {
    width: 120px;
    line-height: 50px;
    cursor: pointer;
    border-bottom: 2px solid;
    border-bottom-color: #ede7da;
    margin-right: 20px;
    text-align: center;
  }
  .choose_it {
    color: #409eff;
    border-bottom-color: #409eff;
  }
}
</style>
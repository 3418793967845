<template>
  <div class="book_intro radius" flex :style="backgroundImg">
    <!-- v-loading="loading" -->
    <div class="intro-center">
      <div v-if="options.image" @click="read_book(options, options.page)">
        <img :src="options.image" class="cover" />
      </div>

      <div class="book_intro_center" flex column>
        <div class="book_intro_one">
          <span>{{ options.title || "- -" }}</span>
          <span>
            <span v-show="options.signed_type == 2" class="qianyue">签约</span>
            <span v-show="options.signed_type == 3" class="fufei">付费</span>
            <span v-show="options.is_vip" class="vip_logo">VIP</span>
            <span
              v-for="(item, index) in options.activity_labels"
              :key="index"
              class="act"
              >{{ item.name }}
            </span>
          </span>

          <div class="img-box">
            <img src="../../../assets/images/renqi.png" alt="" />
            <span>{{ options.click || 0 }} <span>人气值</span></span>
          </div>
        </div>

        <div class="author_info">
          <div class="book_intro_three">
            <img :src="options.author_avatar" alt="" />
            <span
              class="name"
              @click="options.author_id ? author_info(options.author_id) : ''"
              v-if="options.author"
              ><span :class="options.author_id ? 'intro_author' : 'un_author'"
                >{{ options.author || " " }} 著</span
              >
            </span>
            <span
              >字数： {{ options.total_words || 0 }}w 字[{{
                options.serial_status || "连载中"
              }}]</span
            >
            <span>收藏：{{ options.collect || 0 }}</span>
            <span>推荐：{{ options.month_ticket_num || 0 }}</span>
          </div>
        </div>
        <p class="book_intro_two">
          <span v-if="options.is_vip === 1" class="is_vip"> </span>
          <span v-if="options.label === 0" style="padding-right: 15px"
            >暂无标签~</span
          >
          <el-tag
            style="margin: 2px 5px 2px 0px"
            :type="label_style[item.style]"
            v-for="(item, index) in options.label"
            :key="index"
            >{{ item.name }}
          </el-tag>
        </p>

        <p class="book_intro_content" flex column>
          <span>{{ options.intro }}</span>
        </p>
        <div class="book_intro_button">
          <button
            type="button"
            class="read_button"
            key-button
            v-text="options.continue_section_page > 0 ? '继续阅读' : '开始阅读'"
            @click="read_book(options, options.continue_section_page)"
          ></button>
          <button
            type="button"
            class="add_bookrack"
            v-text="
              options.is_like != 1 ? '点赞支持' : `已点赞(${options.like})`
            "
            key-button
            :class="options.is_like === 1 ? 'no_like' : ''"
            @click="likes"
          ></button>
          <button
            type="button"
            class="add_bookrack"
            v-text="options.is_read === 1 ? '已在书架' : '加入书架'"
            key-button
            :class="options.is_read === 1 ? 'no_remove' : ''"
            :disabled="options.is_read === 1"
            @click="add_brack"
          ></button>

          <div class="btn" @click="openDia()">
            <img src="../../../assets/images/fenxiang.png" alt="" />
            <span>分享</span>
          </div>

          <!-- <div class="btn">
            <img src="../../../assets/images/jubao.png" alt="" />
            <span>举报</span>
          </div> -->
        </div>
      </div>
    </div>
    <el-dialog
      title="分享作品"
      :visible.sync="dialogVisible"
      width="30%"
      top="30vh"
      :before-close="handleClose"
    >
      <div class="dia-box">
        <div class="geduan"></div>
        <p>分享到:</p>
        <p>复制链接：把复制的链接发给你的好友吧！</p>
        <el-input v-model="inputLink" :disabled="true"></el-input>
        <div class="btn" @click="copyText()">点击复制</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { like_books, favorites } from "@/https/read_books.js";

export default {
  data() {
    return {
      collect_flag: this.options.is_like === 1 ? true : false,
      label_style: ["warning", "danger", "", "info", "success"],
      inputLink: "",
      backgroundImg: {
        backgroundImage: "url(" + require("@/assets/images/bgg.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      dialogVisible: false,
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
      // loading:false
    };
  },
  props: {
    options: {
      type: Object,
    },
  },
  created() {
    // if(this.options){
    //   this.loading = true;
    // }
  },
  watch: {
    // 'loading'(){
    //   this.loading = !this.loading;
    // }
  },
  methods: {
    openDia() {
      this.inputLink = window.location.href;
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
    },
    copyText() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = this.inputLink; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        message: "复制成功!",
        type: "success",
      });
      this.dialogVisible = false;
    },
    // el-input__inner

    // 点赞
    likes() {
      let params = {
        books_id: Number(sessionStorage.getItem("book_id")),
      };
      if (this.had_login()) {
        like_books(params).then((res) => {
          if (res.code === 0) {
            if (this.options.is_like === 1) {
              this.options.is_like = 0;
            } else {
              this.options.is_like = 1;
            }
          } else {
            this.$message.warning(res.msg);
          }
        });
      } else {
        this.$message.warning("登录后，才能点赞哦~");
        this.$store.commit("set_login_type", true);
      }
    },
    // 阅读
    read_book(row, page) {
      if (page) {
        this.$router.push({
          path: "/read_book",
          query: {
            id: Number(sessionStorage.getItem("book_id")),
            page: page,
          },
        });
      } else {
        if (row.page !== 0) {
          this.$router.push({
            path: "/read_book",
            query: {
              id: Number(sessionStorage.getItem("book_id")),
            },
          });
        } else {
          this.$router.push({
            path: "/read_book",
            query: {
              id: Number(sessionStorage.getItem("book_id")),
              page: row.page,
            },
          });
        }
      }
    },
    // 加入书架
    add_brack() {
      let params = {
        books_id: Number(sessionStorage.getItem("book_id")),
      };
      if (this.options.is_read === 0) {
        favorites(params).then((res) => {
          if (res.code === 0) {
            this.options.is_read = 1;
            // this.options.rack_flag = !this.options.rack_glag
          } else {
            this.$message.warning(res.msg);
          }
        });
      } else {
      }
    },
    // 查看作者详情
    author_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.book_intro {
  margin-top: 20px;
  height: 310px;
  background-color: #102030;
  // background: url("../../../assets/images/bgg.png") no-repeat center center;
  background-size: cover;
  opacity: 1;
  border-radius: 5px;
  padding: 15px 0 15px 0;
  display: flex;
  justify-content: center;
  .intro-center {
    width: 1200px;
    display: flex;
    & > div:nth-of-type(1) {
      width: 190px;
      height: 267px;
      overflow: hidden;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 0 5px #f9f2e9 inset;
      position: relative;
    }

    > div .cover {
      width: 100%;
      height: 100%;
      transition: all 0.5s;
      cursor: pointer;
    }

    > div .cover:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }

  .book_intro_center {
    margin-left: 50px;
    width: 890px;
    height: calc(100% - 11px);

    .book_intro_one {
      margin-top: -10px;
      line-height: 40px;
      display: flex;
      align-items: center;

      & span:nth-of-type(1) {
        font-size: 30px;
        // font-weight: 600;
        padding-right: 12px;
        color: #ffffff;
      }

      & span:nth-of-type(2) {
        height: 40px;
        display: flex;
        align-items: center;
        .vip_logo {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          display: inline-block;
          padding: 2px 6px;
          padding-bottom: 20px;
          background: #fc6c28;
          opacity: 1;
          border-radius: 5px;
          font-weight: 300;
          color: #fff;
        }
        .qianyue {
          height: 22px;
          line-height: 19px;
          font-size: 14px;
          color: #fff;
          padding: 2px 6px;
          padding-bottom: 3px;
          background: #3897ff;
          opacity: 1;
          margin-right: 5px;
          border-radius: 5px;
          // display: none;
        }
        .fufei {
          height: 22px;
          line-height: 19px;
          font-size: 14px;
          color: #fff;
          padding: 2px 6px;
          padding-bottom: 3px;
          margin-right: 5px;
          opacity: 1;
          border-radius: 5px;
          background: #d9001b;
        }
        .act {
          height: 22px;
          line-height: 19px;
          font-size: 14px;
          color: #fff;
          padding: 2px 6px;
          padding-bottom: 3px;
          margin-right: 5px;
          opacity: 1;
          border-radius: 5px;
          background: #a4c93e;
        }
      }
      .img-box {
        display: flex;
        align-items: center;
        margin-left: 45px;
        img {
          width: 23px;
          height: 26px;
          margin-right: 12px;
        }
        > span {
          font-size: 16px;
          font-weight: 400;
          color: #ffd030;
          height: 32px;
          > span {
            font-size: 12px;
            color: #ffd030;
          }
        }
      }
    }
    .author_info {
      display: flex;
      margin-top: 25px;
      height: 20px;
      line-height: 20px;
      .name {
        cursor: pointer;
        .intro_author {
          font-size: 14px;
          font-weight: normal;
        }
        .un_author {
          font-size: 14px;
          font-weight: normal;
          // margin-left: 25px;
        }
      }
      span {
        height: 20px;
        line-height: 20px;
        display: inline-block;
      }
      > span {
        margin-right: 76px;
        color: #fff;
        font-size: 14px;
      }
    }

    .book_intro_two {
      margin-top: 14px;
      line-height: 28px;
      font-size: 12px;
      display: flex;
      /deep/.el-tag {
        height: 26px;
        line-height: 25px;
      }
    }

    .book_intro_three {
      color: #fff;
      display: flex;
      align-items: center;
      > img {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin-right: 10px;
      }
      > span {
        margin-right: 50px;
      }
    }

    .book_intro_four {
      padding: 18px 0;
      color: #fff;
      font-size: 14px;
      span {
        margin-right: 58px;
      }
      span:nth-of-type(1) {
        font-size: 14px;
      }

      & span:nth-of-type(2) {
        padding-right: 73px;
        color: #63aee8;
        cursor: pointer;
      }
      .update_time {
        font-size: 14px;
        color: #fff;
      }

      & span:nth-of-type(2):hover {
        color: rgba(79, 156, 251, 1);
      }

      //   & span:last-of-type {
      //     // color: #999;
      //     color: #333333;
      //   }
    }

    .book_intro_content {
      width: 100%;
      height: 48px;
      letter-spacing: 2px;
      line-height: 24px;
      font-size: 14px;
      overflow: hidden;
      margin-top: 20px;
      color: #fff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .book_intro_button {
      margin-top: 30px;
      height: 42px;
      display: flex;
      button {
        width: 123px;
        height: 42px;
        font-size: 16px;
      }

      .read_button {
        background-color: #f56c6c;
        color: #ffffff;
      }

      .read_button:hover {
        opacity: 0.8;
      }

      .add_bookrack {
        color: #f56c6c;
        border: 1px solid #f56c6c;
        margin-left: 40px;
      }

      .add_bookrack:hover {
        background-color: #f56c6c;
        color: #fff;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 123px;
        height: 42px;
        font-size: 16px;
        border-radius: 5px;
        background: #7b889f;
        justify-content: center;
        margin-left: 40px;
        color: #ffffff;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
          margin-right: 11px;
        }
      }

      .btn:hover {
        background: rgba(255, 255, 255, 0.48);
      }
    }
  }
}

.is_vip {
  cursor: pointer;
  display: flex;
  align-items: center;

  .vip_label {
    padding: 2px 10px;
    border-radius: 4px;
    margin: auto 5px;
    font-size: 16px;
    color: #ffffff;
    background: rgb(249, 38, 88);
  }
}

.no_like {
  border: none !important;
  color: #ffffff !important;
  background: #f56c6c;
}
.no_like:hover {
  opacity: 0.8 !important;
}
.no_remove {
  // background: #cbcbcb !important;
  // color: #ffffff !important;
  border: none !important;
  background: #7b889f !important;
  color: #fff !important;
}

/deep/ .vip_show {
  z-index: 100 !important;
}

.dia-box {
  p {
    margin-bottom: 20px;
  }
  p:nth-of-type(2) {
    color: #ccc;
  }
  .btn {
    width: 127px;
    height: 41px;
    line-height: 41px;
    text-align: center;
    color: #fff;
    background-color: #409eff;
    border-radius: 40px;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
